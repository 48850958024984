@media screen and (max-width: 480px)
{
    .membercard {
        /* width: 46vw;
        height: 37vh; */
        width: 66vw;
        margin-left: 9vw;
        height: 65%;
    }
    .membercard figure img{
        right:unset;
        left:auto;
    }
    .membercard figcaption{
        height: 237px;
    }

}

.membercard figure h1{
    font-size: 1.4rem;
}
.cornerBtn{
    position:absolute;
    top:20px;
    right:50px;
    padding:10px;
    font-size: 20px;
    font-family: 'Yanone Kaffeesatz',  sans-serif;
    letter-spacing:.5px;
    border-radius: 5px;
    background-image: linear-gradient(to right, #eb5176, #f05e81, #f56a8c, #fa7696, #ff82a1);
    color:white;
}
.cornerBtn:hover{
    border-radius: 5px;
    background-image: linear-gradient(to right, #11eedc, #04faee);
    color:white;
}

@media screen and (max-width: 480px)
{
    .cornerBtn{
        right:20px;
        font-size:16px;
    }
}