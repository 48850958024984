#portal .container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
  }
  .container .modal {
    z-index: 1;
    width: 400px;
    height: fit-content;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 60px;
    position: relative;
    animation: animate 0.3s;
    flex-direction: column;
  }
  .sharebutton
  {
      display: flex;
      justify-content: center;
      padding: 10px;
      align-items: center;
      width: 100%;
      margin:10px;
  }

  .modal button {
    position: absolute;
    top: 7px;
    right: 7px;
    padding: 8px 11px;
    background: crimson;
    color: white;
    font-weight: bold;
    border: none;
    outline: none;
    border-radius: 2px;
    cursor: pointer;
  }
  @keyframes animate {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }
  .iconer{
      position :relative;
      margin-left:100px;
      display: inline-block;
  }

  @media screen and  (max-width:450px)
  {
      .iconer{
          margin-left:70px;
          height:20px;
      }
  }