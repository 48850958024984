@media screen and (max-width: 480px)
{
    .MemberMembercard {
        /* width: 46vw;
        margin-left: 24vw; */
        width: 66vw;
        margin-left: 16vw;
        height: 65%;
    }
    .membercard figure img{
        right:unset;
        left:auto;
    }
    .membercard figcaption{
        height: 237px;
    }
    .team__container{
        flex-direction:column;
        align-items: center;
        justify-content: center;
    }
    .team__container .membercard a figure img{
        width:100%;
    }
}
.membercard figure h1{
    font-size: 1.4rem;

}

