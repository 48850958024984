.the-error-container{
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	min-height: 100vh;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.message{
    font-weight: 700;
    text-align: center;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
/* POST DOES NOT EXISTS */
#postdoesnotexist{
    font-size: 5rem;
    margin: 20px;
    text-align: center;
}
@media(max-width:480px){
    #postdoesnotexist{
        font-size: 3.4rem;
    }
}
/* POST DOES NOT EXISTS */