.card-container {
  height: 485px;
  top: 20px;
  width: 390px;
  margin: 15px 25px;
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
@media(max-width:745px){
  .card-container{
    margin: 3% !important;
  }
}
.card-container.flipped .front {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.card-container.flipped .back {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
}

.card-container .front,
.card-container .back {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: block;
  background:  #fff;
  -webkit-box-shadow:  0 0 10px 2px  aqua;
          box-shadow:  0 0 10px 2px  rgba(68, 0, 255, 0.329);
  padding: 10px;
  position: absolute;
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition: -webkit-transform ease 500ms;
  -webkit-transition: -webkit-transform ease 500ms;
  transition: transform ease 500ms;
  transition: transform ease 500ms, -webkit-transform ease 500ms;
  overflow: hidden;
}

.card-container .front {
  z-index: 2;
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  /* front tile styles go here! */
}

.card-container .back {
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.card-container .back p{
    color: #000;
}
.card-container .back a{
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  background-color: dodgerblue;
  background: #4776E6;  
  background: -webkit-linear-gradient(to right, #8E54E9, #4776E6);
  background: linear-gradient(to right, #8E54E9, #4776E6); 
  padding: 8px 18px;
  border-radius: 7px;
}
.card-container .back a:hover{
  text-decoration: none;
}
.card-container .image-container {
  position: relative;
}

.card-container .image-container .card-image {
  width: 100%;
  border-radius: 10px;
}

.card-container .image-container .title {
  width: 100%;
  text-align: left;
  font-size: 27px;
  font-weight: 600;
  position: absolute;
  margin-bottom: -60px;
  bottom: 0;
  left: 0;
  color: #000;
  letter-spacing: 1px;
}

.card-container .main-area {
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
}

.card-container .main-area .blog-post {
  height: 100%;
}

.card-container .main-area .blog-post .date {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 20px;
}

.card-container .main-area .blog-post .read-more {
  height: 90%;
  font-size: 13px;
  color: #000;
  position: relative;
  bottom: 0px;
  text-align: center;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.card-container .main-area .blog-post .blog-content {
  height: 90%;
  display: block;
  color: #000;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media(max-width:600px){
.card-container {
  min-height: 450px;
  margin: 0;
  padding: 0;
  top: 20px;
  width: 330px;
  margin: 40px 20px;
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.card-container .image-container .card-image {
  height: auto;
  min-height: 306px;
}
}

@media(max-width:740px){
  .card-container{
    margin: 25px 0; 
  }
}
@media(max-width:870px){ 
  .card-container{
    margin-bottom: 40px;
  }  
  .card-container .image-container .card-image {
    width: 100%;
    border-radius: 10px;
  }
  .card-container .main-area .blog-post .read-more {
    margin-top:    30px;
    margin-bottom: 30px;
  }
}