
.page-container{
    background: linear-gradient(60deg, rgb(118, 195, 243), rgb(228, 108, 228));
      padding-bottom: 50px;
      min-height: 100vh;
      background-attachment: fixed;
      display:flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
  }
  .cards-section{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      min-height: 70vh;
      margin-bottom: 80px;
  }
  /* Pagination CSS */
  .paginationBtns{
      display: flex;
      justify-content: center;
      align-items: center;
      height:40px;
      width: 300px;
      padding:10px;
      list-style: none;
      font-family: 'Yanone Kaffeesatz',  sans-serif;
      font-size: 14px;
      letter-spacing:0.3px;
  }
  .paginationBtns a{
      padding:10px;
      margin:8px;
      border-radius:5px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 1px;
      border:1px solid #ff156f;
      color:#ff156f;
      cursor: pointer;
      text-decoration: none;
  }
  .paginationBtns a:hover{
      color:white;
      background-color:#ff156f;
  }
  .paginationActive a
  {
      color:white;
      background-color:#ff156f;
  }
  /* Pagination CSS */
  .navbar{
      display: flex;
      justify-content: center;
      width: 100%;
  }
  .thelogo{
      height: 100px;
      width:  100px;
      border-radius: 7px;
  }
  .abs-cca{
      position: absolute;
      left: 10px;
  }
  .cell-container{
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  .cell-container a{
      color: white;
      text-transform: uppercase;
  }
  .cell-container a h1{
      font-weight: 600 !important;
      letter-spacing: 1px;
      font-size:40px;
  }
  .cell-container a:hover{
      text-decoration: none;
  }
  @media(max-width:500px){
      .navbar{
          height: 87px;
      }
      .cell-container{
          position: absolute;
          right: 10px;
          margin-top: 30px;
          flex-direction: column;
      }
      .cell-container a h1{
          margin-top:10px;
          align-content: center;
          font-size: 20px;
      }
      .thelogo{
          height: 60px;
          width:  60px;
      }
  }
  
  a{
      cursor:pointer;
  }
  
.error-container{
    min-height: 70vh;
    flex: 1;
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 5rem;
    color: #fff;
    font-weight: 700;
}
@media(max-width:455px){
    .error-container{
        font-size: 3.5rem;
        flex-direction: column;
    }
    .block455px{
        display: block;
    }
}