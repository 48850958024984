@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap');

#error {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

#error p{
    color: red;
    font-size: 60px;
    font-family: 'Roboto Mono', monospace;
}