@import url("https://fonts.googleapis.com/css2?family=Alata&display=swap");
/* Globals For audition Portal */

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

:root {
    --font-family: "Manrope", sans-serif;

    --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
    --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

    --color-bg: #040c18;
    --color-footer: #031b34;
    --color-blog: #042c54;
    --color-text: #81afdd;
    --color-subtext: #ff8a71;
    --color-gray: gray;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}

.padding-medium {
    padding: 10 !important;
}

.box_p {
    font-family: var(--font-family);
}

.text__bold {
    font-weight: bold !important;
}

/* New Form */
.formSection {
    width: 100%;
    margin: 0 auto;
    font-family: var(--font-family);
    height: 100%;
    background-color: #0080ff;
    background: #011224;
}

.contactForm {
    width: 90%;
    height: 100%;
    box-shadow: 0 50px 50px rgba(0, 0, 0, 0.5);
    background: var(--color-footer);
    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 30% -100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 30% -100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 30% -100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 30% -100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 30% -100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.formSection .contactForm h2 {
    padding-bottom: 10px;
    font-size: 40px;
    text-align: center;
    font-weight: 500;
}

.contactForm label {
    color: var(--color-subtext);
    font-size: 15px;
    font-weight: 400;
    transition: 0.3s;
    pointer-events: none;
}

.contactForm input,
.contactForm input:focus,
.contactForm textarea,
.contactForm textarea:focus,
.contactForm select,
.contactForm select:focus {
    font-size: 15px;
    font-weight: 400;
    color: var(--color-text);
    background: transparent;
    border: none;
    border-color: var(--color-subtext);
    border-bottom: 1px solid var(--color-subtext);
    outline: none;
    box-shadow: none;
}

.contactForm textarea {
    min-height: 100px;
}

.contactForm .customBtn,
.contactForm .customBtn:focus {
    width: 200px;
    outline: none;
    border: none;
    background: var(--gradient-bar);
}

@media (max-width: 600px) {
    .formSection .contactForm {
        padding: 20px !important;
    }

    .formSection .contactForm h2 {
        font-size: 22px;
        font-weight: 700;
    }
}

@media (min-width: 994px) {
    .contactForm .row {
        padding-left: 9% !important;
    }
}

/* Form section ended */

/* Hero Section Starts */
.gpt3__header {
    display: flex;
    flex-direction: row;
}

.gpt3__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.gpt3__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.gpt3__header-content p,
.modal_right_p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.gpt3__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
    flex-direction: row;
}

.gpt3__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.gpt3__header-content__input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #ff4820;
    border: 2px solid #ff4820;
    padding: 0 1rem;
    color: #fff !important;
    cursor: pointer;
    outline: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.gpt3__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-top: 2rem;
}

.gpt3__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.gpt3__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.gpt3__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gpt3__header-image img {
    width: 90%;
    height: 90%;
}

@media screen and (max-width: 1050px) {
    .gpt3__header {
        flex-direction: column;
    }

    .gpt3__header-content {
        margin: 0 0 3rem;
    }

    .menuIcon {
        display: none;
    }
}

@media screen and (max-width: 512px) {
    .menuIcon {
        display: flex;
    }
}

@media screen and (max-width: 650px) {
    .gpt3__header-image img {
        /* width: 100%; */
        height: 100%;
    }

    .gpt3__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .modal_right h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .gpt3__header-content p,
    .modal_right_p {
        font-size: 16px;
        line-height: 24px;
    }

    .gpt3__header-content__people {
        flex-direction: column;
    }

    .gpt3__header-content__people p {
        margin: 0;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button,
    .modal_btn {
        font-size: 16px;
        line-height: 24px;
    }

    .gpt3__header-image img {
        width: 100%;
        /* height: 100%; */
    }
}

@media screen and (max-width: 490px) {
    .gpt3__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .modal_right h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .gpt3__header-content p,
    .modal_right_p {
        font-size: 14px;
        line-height: 24px;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button,
    .modal_btn {
        font-size: 12px;
        line-height: 16px;
    }

    .formSection .contact_container .contactForm h2 {
        font-size: 30px;
    }
}

/* Header Section Ends */
/* NavBar Section Starts */
.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.gpt3__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.gpt3__navbar-links_logo {
    margin-right: 2rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
}

.gpt3__navbar-links_logo:hover {
    text-decoration: none;
}

.gpt3__navbar-links_logo img {
    /* width: 62.56px; */
    height: 32.56px;
    /* height: 16.02px; */
}

.avatar {
    height: 35.56px;
    margin-right: 10px;
    cursor: pointer
}

.gpt3__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.gpt3__navbar-links_logo p {
    font-family: var(--font-family);
    font-weight: 700;
    padding-top: 10px;
    padding-left: 10px;
    font-size: 18px;
    text-decoration: none;
}

.gpt3__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.gpt3__navbar-sign button,
.button-drop {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #ff4820;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.dropdown {
    display: none;
}

@media screen and (max-width: 700px) {
    .gpt3__navbar {
        padding: 2rem 4rem;
    }

    .gpt3__navbar-sign {
        display: none;
    }

    .dropdown {
        display: block !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
    }

    .dropdown-basic {
        background-color: transparent !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
    }

    .dropdown-basic:focus {
        background: transparent !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
    }

    .dropdown-basic {
        background: transparent !important;
        border: none !important;
    }
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

@media screen and (max-width: 550px) {
    .gpt3__navbar {
        padding: 2rem;
    }
}

/* Navbar Section Ends*/

/* AAROHAN SECTION BEGINS*/
.cell__section__carousel {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.aarohan__section {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.aarohan__section .carousel_cont {
    width: 600px;
    height: 450px;
    flex: 0.6;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    z-index: 1;
}

.aarohan__section .carousel_cont .carousel-item {
    height: 100% !important;
    width: 100% !important;
}

.carousel-inner {
    height: 100% !important;
    width: 100% !important;
    overflow: hidden !important;
}

.aarohan__section .carousel_cont .carousel-item::after {
    height: 100% !important;
    width: 100% !important;
    overflow: hidden !important;
    content: "";
    left: 0;
    top: 0;
    position: absolute;
    opacity: 0.5;
    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 30% 100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 30% 100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 30% 100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 30% 100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 30% 100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.aarohan__section .carousel_cont .carousel-item img {
    height: 100%;
    width: 100%;
}

@media (max-width: 600px) {
    .aarohan__section .carousel_cont {
        width: 100%;
        height: 400px;
    }
}

.aarohan__section_box p {
    font-family: var(--font-family);
    color: var(--color-text);
}

.aarohan__section_box h2 {
    font-family: var(--font-family);
    color: var(--color-text);
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* AAROHAN SECTION ENDS */

/* Shared */

.gradient__bg {
    background: -moz-radial-gradient(circle at 3% 25%,
            rgb(0, 40, 83) 0%,
            rgba(4, 12, 24, 1) 25%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 3% 25%,
            rgba(0, 40, 83, 1) 0%,
            rgba(4, 12, 24, 1) 25%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 3% 25%,
            rgba(0, 40, 83, 1) 0%,
            rgba(4, 12, 24, 1) 25%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 3% 25%,
            rgba(0, 40, 83, 1) 0%,
            rgba(4, 12, 24, 1) 25%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 3% 25%,
            rgba(0, 40, 83, 1) 0%,
            rgba(4, 12, 24, 1) 25%);
}

.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }

    .section__margin {
        margin: 4rem;
    }
}

@media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 1rem;
    }

    .section__margin {
        margin: 4rem 1rem;
    }

    .gpt3__whatgpt3-heading {
        margin: 2rem 0 !important;
    }

    /* Hover Effect   */
    .gpt3__features-container__feature:hover {
        transform: scale(1.15) !important;
        position: relative;
        box-shadow: 0px 15px 15px #040c18 #042c54;
        border-radius: 5px;
        background: var(--color-blog);
        padding: 0px 5px;
        padding-left: 15px;
        transition: 0.5s ease-in-out;
    }
}

/* Shared Ends */

/* CellInfo Starts */
.cellinformation {
    background: url("./img/section_back4.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 70px 70px;
}

.gpt3__whatgpt3 {
    display: flex;
    flex-direction: column;

    padding: 2rem;
    background: var(--color-footer);

    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 30% -100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 30% -100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 30% -100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 30% -100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 30% -100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gpt3__whatgpt3-feature {
    display: flex;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-feature .gpt3__features-container__feature {
    margin: 0;
    padding-left: 25px;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
    /* max-width: 700px; */
    max-width: 100%;
}

.gpt3__whatgpt3-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 4rem 0 2rem;
}

.gpt3__whatgpt3-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.gpt3__whatgpt3-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
}

.gpt3__whatgpt3-container {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: row; */
    flex-direction: column;
    transition: 0.5s ease-in-out;
    margin-top: 2rem;
}

.gpt3__whatgpt3-container .gpt3__features-container__feature {
    flex: 1;
    margin: 1rem;
    /* min-width: auto; */
    width: 100%;
    /* min-width: 150px; */
    display: unset;
    flex-direction: column;
    margin-left: 0;
}

.gpt3__whatgpt3-container .gpt3__features-container_feature-text {
    margin-top: 0.5rem;

}

@media screen and (max-width: 850px) {
    .gpt3__whatgpt3-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .gpt3__whatgpt3-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    .gpt3__whatgpt3-feature .gpt3__features-container__feature {
        flex-direction: column;
    }

    .modal_container {
        width: calc(100vw - 50px) !important;
        padding: 20px 10px;
    }

    .gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .cellinformation {
        background: #011224;
        padding: 30px 15px;
    }

    .gpt3__whatgpt3-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 350px) {
    .gpt3__whatgpt3-container .gpt3__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}

/* CellInfo Ends */
/* Feature Begins */
.gpt3__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    cursor: pointer;
    margin: 1rem;
    transition: 0.5s;
}

/* Hover Effect   */
.gpt3__features-container__feature:hover {
    transform: scale(1.2);
    position: relative;
    box-shadow: 0px 15px 15px #040c18 #042c54;
    border-radius: 5px;
    background: var(--color-blog);
    padding: 0px 5px;
    padding-left: 25px;
    transition: 0.5s ease-in-out;
}

.gpt3__features-container__feature:hover .gpt3__features-container__feature-title>div {
    width: 100%;
}

.gpt3__features-container__feature-title {
    flex: 1;
    /* max-width: 180px; */

    margin-right: 2rem;
}

.gpt3__features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gpt3__features-container__feature-title div {
    transition: 0.35s;
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.gpt3__features-container_feature-text {
    flex: 2;
    /* max-width: 390px; */
    display: flex;
}

.gpt3__features-container_feature-img_desktop_only {
    display: none;
}

.gpt3__features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
    transition: 0.5s ease-in-out;
}

.gpt3__features-container_feature-img_mobile_only {
    width: 40px;
    border-radius: 50%;
}

@media screen and (min-width: 650px) {
    .gpt3__features-container_feature-img_mobile_only {
        display: none;
    }

    .gpt3__features-container_feature-text p {
        flex: 0.9;
    }

    .gpt3__features-container_feature-img_desktop_only {
        flex: 0.1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 15px;
    }

    .gpt3__features-container_feature-img_desktop_only img {
        width: 60px;
        border-radius: 50%;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .gpt3__features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .gpt3__features-container__feature {
        margin: 1rem 0;
    }
}

/* Features End */

/* MODAL begins */

.modal_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 620px;
    position: relative;
    background: var(--color-footer);
    padding: 60px 30px;
    transition: 0.5s;

    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 30% -100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 30% -100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 30% -100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 30% -100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 30% -100%,
            #042c54 25%,
            rgba(4, 44, 84, 1) 85%,
            rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    animation: scale-up-center 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    box-shadow: 0px 7px 7px #81afdda8;
}

.modal_container .modal_right_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.modal_right_container>h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.04em;
    align-self: flex-start;
    padding-bottom: 10px;
}

.modal_btn {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #ff4820;
    border: 2px solid #ff4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;
    margin-top: 30px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

/* do corrections */
@media screen and (max-width: 768px) {

    .modal_container,
    .modal_right_container {
        width: 500px;
        padding: 10px 10px;
    }

    .modal_right_container {
        width: 500px;
        padding: 10px 10px;
    }

    .modal_right_container>h1 {
        font-size: 28px;
    }
}

/* Footer begins */
.gpt3__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #011224;
}

.gpt3__footer-copyright p {
    font-size: 15px;
    text-align: center;
    font-family: var(--font-family);
    font-weight: 900;
    z-index: 100;
    line-height: 15px;
    color: #fff;
}