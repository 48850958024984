.background {
  background: linear-gradient(60deg, rgb(118, 195, 243), rgb(228, 108, 228));
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 120px 16% 60px 16%;
}
@media (max-width: 1100px) {
  .background {
    padding: 100px 10% 60px 10%;
  }
}
@media (max-width: 800px) {
  .background {
    padding: 100px 3% 60px 3%;
  }
  #my-child-container iframe {
    width: 100% !important;
    min-height: 300px;
    border-radius: 5px;
  }
}
@media (max-width: 600px) {
  body .my-container {
    padding: 1rem;
    padding-bottom: 50px !important;
  }
  body .my-container .img-container img {
    width: 100%;
    height: unset;
    min-height: unset;
    max-height: unset;
    min-width: unset;
    border-radius: 5px;
    max-width: unset;
  }
  #my-child-container iframe {
    min-height: 300px !important;
    border-radius: 5px;

  }
}
@media (max-width: 450px) {
  body .my-container {
    padding: 12px 10px !important;
    padding-bottom: 40px !important;
  }
  body .my-container .title {
    font-size: 32px !important;
  }
  body .my-container #subtitle {
    font-size: 27px;
  }
  body .my-container p {
    font-size: 18px !important;
    line-height: 36px !important;
  }
  #my-child-container iframe {
    min-height: 250px !important;
    border-radius: 5px;
  }
}
body .my-container {
  background-color: #fff;
  max-width: 1000px;
  min-width: 65vw;
  padding: 0.5rem 3rem;
  padding-bottom: 80px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.034);
  border-radius: 8px;
  transition: 0.5s;
  margin-top: 6vw;
  overflow: hidden;
}
body .my-container:hover {
  box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.13);
  transition: 0.5s;
}
body .my-container .title {
  font-size: 48px;
  font-weight: 700;
  padding: 15px 0;
  text-overflow: ellipsis;
  word-wrap: break-word;
  text-align: center;
}
body .my-container #subtitle {
  font-weight: 700;
}

body .my-container .img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px !important;
}
body .my-container .img-container img {
  max-height: 500px;
  max-width: 600px;
  padding: 15px 0;
  border-radius: 10px !important;
  cursor:pointer;
}
body .my-container p {
  font-size: 2.2rem;
  font-weight: 500;
  overflow: hidden;
  line-height: 45px;
}
.thelines {
  margin-top: 10px !important;
  opacity: 1 !important;
}
#my-child-container {
  width: 100%;
  margin-top: 50px;
}
#my-child-container h1 {
  font-weight: 700;
}
#my-child-container iframe {
  width: 100% !important;
  min-height: 450px;
  border-radius: 5px;
}
.detail-header{
  position: absolute;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.252);
  padding: 4px 0;
}
.detail-header a{
  color:  unset !important;
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.252));
}

.detail-header a:hover{
  color: unset;
  text-decoration: none;
}
@media(max-width:400px){
  .detail-header{
    letter-spacing: unset !important;
    font-size: 32px;
  }
}
.social-media-container{
  padding: 10px;
  display: flex;
  justify-content: center;
}
.social-media-container a{
  text-decoration: none;
  color: white;
  margin: 12px 17px;
  font-size: 24px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid transparent;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.24);
  transition: 0.2s;
}
.social-media-container a:hover{
  transition: 0.2s;
}
@media(max-width: 425px){
  .social-media-container a{
    margin: 8px 12px;
    font-size: 22px;
    padding: 10px 15px !important;
  }
}
/* Social Media HOver Effects */

.fb{
  background: #3b5998 ;
}
.fb:hover{
  background: transparent;
  border: 1px solid #3b5998;
  color: #3b5998;
}
.ig{
  background: linear-gradient(49.2deg, #f9ed32, #ee2a7b, #002aff);
}
.ig:hover{
  background: transparent;
  border: 1px solid #ff156f;
  color: #ff156f;
}
.li{
  background: #0077b5;
}
.li:hover{
  background: transparent;
  color: #0077b5;
  border: 1px solid #0077b5;
}
.yt{
background:red;
}
.yt:hover{
  background: transparent;
  color: red;
  border: 1px solid red;
}
.link-tags{
  display: block;
}
.link-tags .tag-title{
  margin-bottom: 18px;
}
.tag-link{
  font-size: 18px;
}


/* Share button CSS */
.share-container{
  display: flex;
  justify-content: center;
  margin: 18px 0;
}
.share{
  color: #ff156d;
  border: 2px solid #ff156d;
  background-color: #fff;
  font-size: 18px;
  border-radius: 4px;
  font-weight: 600;
  padding: 6px 14px;
}
.share:focus{
  border: 2px solid dodgerblue !important;
  outline: none !important;
  background:dodgerblue;
  color: white;
}
/* Share button CSS */