#loader2svg>svg {
  width: 200px;
  /* height: 200px; */

  /* new one */
  transform: translateX(-50%)translateY(-50%);


/* below part for center alignment */
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  
}

#loader2svg{
  z-index: 99999; 
  height: 100vh; 
  width: 100%; 
  text-align: center; 
  position: absolute; 
  display: block; 
  background: linear-gradient(#000000, #3d3d3d);
}

@-moz-keyframes draw {
  0% {
    stroke: #42c0ff;
  }
  100% {
    stroke-dashoffset: 300;
  }
}
@-webkit-keyframes draw {
  0% {
    stroke: #42c0ff;
  }
  100% {
    stroke-dashoffset: 300;
  }
}
@keyframes draw {
  0% {
    stroke: #42c0ff;
  }
  100% {
    stroke-dashoffset: 300;
  }
}
@-moz-keyframes letterflash {
  0% {
    fill: #42c0ff;
  }
  100% {
    fill: #ffa100;
  }
}
@-webkit-keyframes letterflash {
  0% {
    fill: #42c0ff;
  }
  100% {
    fill: #ffa100;
  }
}
@keyframes letterflash {
  0% {
    fill: #42c0ff;
  }
  100% {
    fill: #ffa100;
  }
}
@-moz-keyframes spinnerflash {
  0% {
    stroke: #42c0ff;
  }
  100% {
    stroke: #ffa100;
  }
}
@-webkit-keyframes spinnerflash {
  0% {
    stroke: #42c0ff;
  }
  100% {
    stroke: #ffa100;
  }
}
@keyframes spinnerflash {
  0% {
    stroke: #42c0ff;
  }
  100% {
    stroke: #ffa100;
  }
}
.circle {
  stroke-dasharray: 150;
  -moz-animation: draw 2s linear infinite;
  -webkit-animation: draw 2s linear infinite;
  animation: draw 2s linear infinite;
}

.one, .two, .three, .four, .five, .six {
  -moz-animation: letterflash 2s ease-in-out infinite;
  -webkit-animation: letterflash 2s ease-in-out infinite;
  animation: letterflash 2s ease-in-out infinite;
}

.six {
  -moz-animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.five {
  -moz-animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.four {
  -moz-animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.three {
  -moz-animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.two {
  -moz-animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.one {
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}